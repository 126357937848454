import { render, staticRenderFns } from "./DrawerProfil.vue?vue&type=template&id=46456c8a"
import script from "./DrawerProfil.vue?vue&type=script&lang=ts"
export * from "./DrawerProfil.vue?vue&type=script&lang=ts"
import style0 from "./DrawerProfil.vue?vue&type=style&index=0&id=46456c8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCtaLink: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-link/cta-link.vue').default,MoleculesDrawer: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/drawer/drawer.vue').default})
