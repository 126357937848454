import { render, staticRenderFns } from "./OffersPageContent.vue?vue&type=template&id=19980b3e"
import script from "./OffersPageContent.vue?vue&type=script&setup=true&lang=ts"
export * from "./OffersPageContent.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OffersPageContent.vue?vue&type=style&index=0&id=19980b3e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesNotificationCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/notification-card/notification-card.vue').default,FoundationsUiIcon: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-icon/ui-icon.vue').default,AtomsCtaLabel: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-label/cta-label.vue').default,MoleculesLoader: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/loader/loader.vue').default,PopinDevis: require('/vercel/path0/components/PopinDevis.vue').default,PopinNoOffer: require('/vercel/path0/components/PopinNoOffer.vue').default,Offers: require('/vercel/path0/components/Offers.vue').default,AdvantagesPush: require('/vercel/path0/components/AdvantagesPush.vue').default,DrawerProfil: require('/vercel/path0/components/DrawerProfil.vue').default,DrawerNeeds: require('/vercel/path0/components/DrawerNeeds.vue').default,DrawerDocuments: require('/vercel/path0/components/DrawerDocuments.vue').default})
