
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { isNil } from 'lodash' /* eslint-disable-line import/named */

import { pushNeeds } from '~/plugins/gtm'
import { type FormState } from '~/store/form/state'
import { type PrismicState } from '~/store/prismic/state'
import { type TgpState } from '~/store/tgp/state'
import { apiDate } from '~/utils/date' /* eslint-disable-line import/named */
import { sentryContext } from '~/utils/sentry'
import { basicHealthCoversToForm, basicHealthCoversToTgp } from '~/utils/form'

export default defineComponent({
  name: 'DrawerNeeds',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...(mapGetters({
      stepsData: 'prismic/stepsData',
      basicHealthCovers: 'form/basicHealthCovers',
      tgpBasicHealthCovers: 'tgp/basicHealthCovers',
    }) as {
      stepsData: () => PrismicState['stepsData']
      basicHealthCovers: () => FormState['basicHealthCovers']
      tgpBasicHealthCovers: () => TgpState['basicHealthCovers']
    }),
    formattedData() {
      return {
        title: this.$prismic.asHTML(
          this.$store.state.prismic.stepsData.offers?.drawer_needs_title
        ),
        subtitle: this.$prismic.asHTML(
          this.$store.state.prismic.stepsData.offers?.drawer_needs_subtitle
        ),
      }
    },
    sentryContext() {
      const payload = sentryContext(this.$store.state.tgp, 'drawer-needs')

      return payload
    },
  },
  watch: {
    tgpBasicHealthCovers: function () {
      // set basic health covers levels if it is updated in store tgp (e.g. after needs update when from comparator)
      this.setBasicHealthCoversFromTgp()
    },
  },
  mounted() {
    this.setBasicHealthCoversFromTgp()
  },
  methods: {
    setLevel({ key, value }: { key: string; value: number | boolean }) {
      this.$store.dispatch('form/setBasicHealthCovers', { [key]: value })
    },
    async onSubmit() {
      this.loading = true
      const basicHealthCovers = basicHealthCoversToTgp(this.basicHealthCovers)

      this.$store.dispatch('tgp/setBasicHealthCovers', basicHealthCovers)
      await this.updateNeeds()
      pushNeeds({ $gtm: this.$gtm, store: this.$store })
    },
    async updateNeeds() {
      const projectId = this.$store.state.tgp.projectId
      const effectiveDate = apiDate(this.$store.state.tgp.dateEffet)

      const payload = {
        projectId,
        effectiveDate,
        ...basicHealthCoversToTgp(this.basicHealthCovers),
      }

      await fetch(`${this.$config.public.baseURL}/api/needs`, {
        method: 'PUT',
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          this.loading = false

          if (response.ok) {
            this.handleToggle()

            // once needs have been updated, offers need to be refreshed
            await this.$store.dispatch('fetchOffers', {
              baseUrl: this.$config.public.baseURL,
              sentryContext: this.sentryContext,
            })
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleToggle() {
      ;(this.$refs['drawer-component'] as any).handleToggle()
    },
    setBasicHealthCoversFromTgp() {
      if (!isNil(this.tgpBasicHealthCovers)) {
        // set basic health covers levels if already exists in store tgp
        this.$store.dispatch(
          'form/setBasicHealthCovers',
          basicHealthCoversToForm(this.tgpBasicHealthCovers)
        )
      }
    },
  },
})
