
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { TgpState } from '../store/tgp/state'
import { stringToBoolean, getStatutProLabel } from '../utils/form'
import { PrismicState } from '../store/prismic/state'
import { type Address, type Contact } from '~/types/global'
import { goTo, Router } from '~/utils/navigation'
import { pushCtaClick } from '~~/plugins/gtm'

export default defineComponent({
  name: 'DrawerProfil',
  computed: {
    ...(mapGetters({
      mandatorySchemes: 'prismic/mandatorySchemes',
    }) as {
      mandatorySchemes: () => PrismicState['mandatorySchemes']
    }),

    formattedData() {
      return {
        title: this.$prismic.asHTML(
          this.$store.state.prismic.stepsData.offers?.drawer_profil_title
        ),
        subtitle: this.$prismic.asHTML(
          this.$store.state.prismic.stepsData.offers?.drawer_profil_subtitle
        ),
        tgp: this.$store.state.tgp as TgpState,
      }
    },
  },
  methods: {
    getProjectSituationLabel() {
      const projectSituation = this.formattedData.tgp.projectSituation

      if (stringToBoolean(projectSituation?.hasIndividualPolicyInsurance)) {
        return this.$t('drawerProfil.hasPolicyInsuranceYear')
      } else {
        return this.$t('drawerProfil.hasPolicyInsurance')
      }
    },
    getProjectSituation() {
      const projectSituation = this.formattedData.tgp.projectSituation

      if (
        stringToBoolean(projectSituation?.hasIndividualPolicyInsurance) &&
        stringToBoolean(projectSituation?.isCancelableInsurance)
      ) {
        return this.$t('drawerProfil.projectSituation.yes')
      } else {
        return this.$t('drawerProfil.projectSituation.no')
      }
    },
    getMandatoryScheme(valueTgp: any) {
      return this.mandatorySchemes.find((item: any) => item.value === valueTgp)
        ?.label
    },
    getStatutPro(valueTgpRegime: any, valueTgpStatutPro: any) {
      return getStatutProLabel(
        valueTgpRegime,
        valueTgpStatutPro,
        this.mandatorySchemes
      )
    },
    isCoupleOrCoupleChild() {
      return ['couple', 'coupleChild'].includes(
        this.formattedData.tgp.peopleComposition!
      )
    },
    hasChildren() {
      return ['aloneChild', 'coupleChild'].includes(
        this.formattedData.tgp.peopleComposition!
      )
    },
    getChildBirthdate(index: number) {
      return this.formattedData.tgp[
        `child${index - 1}Birthdate` as keyof TgpState
      ]
    },
    getChildMandatoryScheme(index: number) {
      return this.getMandatoryScheme(
        this.formattedData.tgp[
          `child${index - 1}MandatoryScheme` as keyof TgpState
        ]
      )
    },
    getAddress() {
      if (!this.formattedData.tgp.adresse) {
        return
      }

      const {
        addressNumber,
        addressRoad,
        addressDetail,
        addressZipCode,
        city,
      } = this.formattedData.tgp.adresse as Address

      const parts = [
        addressNumber,
        addressRoad,
        addressDetail,
        addressZipCode,
        city,
      ]

      return parts.filter((part) => part).join(' ')
    },
    getFormattedCivility() {
      const contact = this.formattedData.tgp.contact as Contact

      return `${contact?.civility} ${contact?.surname} ${contact?.firstName}`
    },
    handleToggle() {
      ;(this.$refs['drawer-component'] as any).handleToggle()
    },
    handleEditClick(label: TranslateResult | string, link: string) {
      // track CTA click
      pushCtaClick(
        { $gtm: this.$gtm, store: this.$store },
        { label: `Modifier - ${label}` }
      )
      ;(this.$refs['drawer-component'] as any).handleToggle()
      goTo(this.$router as unknown as Router, link)
    },
  },
})
